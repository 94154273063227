body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.title {
  text-align: center;
}

h1 {
  text-align: center;
}

/* Diseño para temas claros */
html, body {
  scrollbar-width: thin;
  scrollbar-color: #302d2d #cee0ef;
  transition: scrollbar-color 0.3s ease; /* Transición suave */
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: #302d2d;
  border-radius: 10px;
  border: 3px solid #e0f7e0;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Efecto al pasar el mouse */
}

html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background-color: #e0f7e0;
  border-radius: 10px;
}

/* Efecto de hover para el scrollbar */
html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
  background-color: #4caf50; /* Cambia el color en hover */
  transform: scale(1.2); /* Hace que el thumb se agrande ligeramente */
}

/* Diseño para temas oscuros */
.dark-theme {
  scrollbar-color: #90caf9 #424242;
  transition: scrollbar-color 0.3s ease;
}

.dark-theme::-webkit-scrollbar-thumb {
  background-color: #90caf9;
  border: 3px solid #424242;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dark-theme::-webkit-scrollbar-track {
  background-color: #424242;
}

/* Efecto de hover para el scrollbar en modo oscuro */
.dark-theme::-webkit-scrollbar-thumb:hover {
  background-color: #2979ff; /* Color más brillante al pasar el mouse */
  transform: scale(1.2); /* Aumenta el tamaño al pasar el cursor */
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
